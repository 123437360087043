import { addDays } from 'date-fns';
import { AcceptanceReportEditDetail } from './graphql/__generated__/AcceptanceReportEditDetailQuery';
import {
  Acceptance,
  AcceptanceReportFormValues,
  AttendeeFormValues,
  Defect,
  DefectStatus,
} from '../forms/AcceptanceReportForm/AcceptanceReportFormValues';
import { createEmptyImageWithLabel } from '../../../containers/forms/ImageUploadWithLabel/ImageUploadWithLabel';
import { formatISOStringToDateString } from '../../../utils/date';

type AcceptanceReportDefectFormValues = Pick<
  AcceptanceReportFormValues,
  'defect' | 'defectReferenceFile' | 'defectMeta' | 'defectStatus' | 'warranty' | 'correction'
>;

function formatAcceptanceReportDate(date?: string): string {
  return date ? formatISOStringToDateString(date) : '';
}

function getAcceptanceReportDefectFormValues({
  acceptanceReport,
  negativeFlowEnabled,
}: {
  acceptanceReport: AcceptanceReportEditDetail['acceptanceReport'];
  negativeFlowEnabled: boolean;
}): AcceptanceReportDefectFormValues {
  const defect = acceptanceReport.hasDefect ? Defect.HAS_DEFECT : Defect.NOT_DEFECTIVE;

  if (!acceptanceReport.defect) {
    const tomorrow = addDays(acceptanceReport.date, 1);

    return {
      defect,
      defectReferenceFile: '',
      defectMeta: [createEmptyImageWithLabel()],
      defectStatus: DefectStatus.AGREED,
      warranty: { startDate: formatAcceptanceReportDate(tomorrow), note: '' },
      correction: { dueDate: undefined, note: '' },
    };
  }

  return {
    defect,
    defectReferenceFile: acceptanceReport.defect.reference || '',
    defectMeta: (acceptanceReport.defect.metaData ?? []).map((meta) => ({
      imageId: meta.image?.id,
      label: meta.description,
      imageThumbnailUrl: meta.image?.thumbnail,
      imageUrl: meta.image?.url,
      orderId: Math.random().toString(36).slice(2),
    })),
    defectStatus: acceptanceReport.defect.agreed ? DefectStatus.AGREED : DefectStatus.NOT_AGREED,
    warranty:
      acceptanceReport.defect.agreed || !negativeFlowEnabled
        ? {
            startDate: formatAcceptanceReportDate(
              acceptanceReport.defect.dueDate ?? acceptanceReport.defect.correction?.dueDate,
            ),
            note: acceptanceReport.defect.note ?? acceptanceReport.defect.correction?.note ?? '',
          }
        : { startDate: undefined, note: '' },
    correction:
      !acceptanceReport.defect.agreed && negativeFlowEnabled
        ? {
            dueDate: formatAcceptanceReportDate(acceptanceReport.defect.correction?.dueDate),
            note: acceptanceReport.defect.correction?.note ?? '',
          }
        : { dueDate: undefined, note: '' },
  };
}

function getAcceptanceReportAttendeesInitialValues(
  attendees: AcceptanceReportEditDetail['acceptanceReport']['attendees'],
): AttendeeFormValues[] {
  return attendees.map((attendee) => {
    return {
      role: attendee.role,
      fullName: attendee.fullName,
      position: attendee.position || '',
    };
  });
}

export function getAcceptanceReportInitialFormValues({
  acceptanceReport,
  negativeFlowEnabled,
}: {
  acceptanceReport: AcceptanceReportEditDetail['acceptanceReport'];
  negativeFlowEnabled: boolean;
}): AcceptanceReportFormValues {
  return {
    name: acceptanceReport.projectName,
    description: acceptanceReport.description || undefined,
    projectNumber: acceptanceReport.projectNumberComputed ?? '',
    date: formatISOStringToDateString(acceptanceReport.date),
    client: acceptanceReport.clientNameComputed ? acceptanceReport.clientNameComputed : '',
    contractor: acceptanceReport.contractor,
    toProfessions: acceptanceReport.professions || [],
    attendees: getAcceptanceReportAttendeesInitialValues(acceptanceReport.attendees),
    acceptance: acceptanceReport.acceptance ? Acceptance.ACCEPT : Acceptance.NOT_ACCEPT,
    acceptanceDescriptionText: acceptanceReport.acceptanceDescription?.text || '',
    acceptanceDescriptionReference: acceptanceReport.acceptanceDescription?.reference || '',
    ...getAcceptanceReportDefectFormValues({ acceptanceReport, negativeFlowEnabled }),
    attachImagesToPdf: acceptanceReport.attachImagesToPdf,
    images: acceptanceReport.images.map(({ id, label, url, thumbnail }) => ({
      orderId: Math.random().toString(36).slice(2),
      imageId: id,
      imageUrl: url,
      imageThumbnailUrl: thumbnail,
      label: label || undefined,
    })),
  };
}
