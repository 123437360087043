import { Box, BoxProps } from '@hs-baumappe/legacy-ui';

export default function DocumentSkeleton(props: BoxProps): JSX.Element {
  return (
    <Box {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 620 877"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="7.5"
          y="7.5"
          width="605"
          height="862"
          fill="white"
          stroke="#555555"
          strokeWidth="15"
        />
        <rect x="38" y="44" width="173" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="72" width="173" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="100" width="173" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="236" width="363" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="272" width="537" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="303" width="537" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="334" width="537" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="365" width="537" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="396" width="537" height="19" rx="9.5" fill="#D9D9D9" />
        <rect x="38" y="427" width="537" height="19" rx="9.5" fill="#D9D9D9" />
      </svg>
    </Box>
  );
}
